.why-us-container{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.why-us-container .why-us-inner{
    width: var(--inner-width);
    display: flex;
}
.why-us-container .why-us-inner .image-holder{
    width: 55%;
    position: relative;
    z-index: -1000;
}
.why-us-container .why-us-inner .image-holder img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.why-us-container .why-us-inner .image-holder .inner-div{
    position: absolute;
    width: 70%;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(28, 39, 82, 0.6);
    backdrop-filter: blur(8px); 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.why-us-container .why-us-inner .image-holder .inner-div .counter-container{
    display: flex;
    flex-direction: column;
}
.why-us-container .why-us-inner .image-holder .inner-div .counter-container .counter-row{
    display: flex;
    flex-direction: row;
    border: 1px solid #7E7E7E;
    font-family: var(--header-font);
    font-weight: bold;
    margin-bottom: 15px;
}
.why-us-container .why-us-inner .image-holder .inner-div .counter-container .counter-row .numb,.text{
    padding: 10px;
}
.why-us-container .why-us-inner .image-holder .inner-div .counter-container .counter-row .numb{
    background-color: var(--light-blue-color);
    min-width: 70px;
    text-align: center;
    color: var(--dark-blue-color);
}
.why-us-container .why-us-inner .image-holder .inner-div .counter-container .counter-row .text{
    min-width: 180px;
    color: var(--white-color);
}
.why-us-container .why-us-inner .text-holder{
    width: 45%;
    padding-left: 30px;
    background-color: var(--white-color);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.why-us-container .why-us-inner .text-holder .sub-topic{
    padding: 8px 16px 8px 16px;
    background-color: var(--light-grey-color);
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.why-us-container .why-us-inner .text-holder .sub-topic img{
    width: 25px;
    height: 25px;
}
.why-us-container .why-us-inner .text-holder .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
}
.why-us-container .why-us-inner .text-holder .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
}
.why-us-container .why-us-inner .text-holder .sub-description{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    margin-top: 5px;
    font-size: var(--font-s-normal);
}
.why-us-container .why-us-inner .text-holder .key-point-holder{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 3px;
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row .checked{
    display: flex;
    align-items: flex-start;
    padding-right: 10px;
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row .checked img{
    margin-top: 3px;
    width: 20px;
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row .descrip{
    display: flex;
    flex-direction: column;
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row .descrip h4{
    font-family: var(--header-font);
    font-size: 15px;
    color: var(--dark-blue-color);
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row .descrip h4::after{
    content: ':';
    margin-left: 5px;
}
.why-us-container .why-us-inner .text-holder .key-point-holder .point-row .descrip p{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    font-size: 14px;
    font-size: var(--font-s-normal);
}
@media screen and (max-width:500px) {
    .why-us-container .why-us-inner{
        width: 90%;
        flex-direction: column;
    }
    .why-us-container .why-us-inner .image-holder{
        width: 100%;
    }
    .why-us-container .why-us-inner .image-holder .inner-div{
        width: auto;
        height: auto;
        padding: 20px;
    }
    .why-us-container .why-us-inner .image-holder .inner-div .counter-container .counter-row .text{
        min-width: 150px;
        font-size: 13px;
    }
    .why-us-container .why-us-inner .image-holder .inner-div .counter-container .counter-row .numb{
        font-size: 13px;
    }
    .why-us-container .why-us-inner .text-holder{
        width: 100%;
        padding-left: 0;
    }
    .why-us-container .why-us-inner .text-holder .key-point-holder{
        width: 100%;
    }
}
