.form-container{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-grey-color);
}
.form-container .form-inner{
    width: var(--inner-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}
.form-container .form-inner .sub-topic{
    margin-top: 20px;
    padding: 8px 16px 8px 16px;
    background-color: var(--white-color);
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.form-container .form-inner .sub-topic img{
    width: 25px;
    height: 25px;
}
.form-container .form-inner .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
    font-size: var(--font-s-normal);
}
.form-container .form-inner .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
    text-align: center;
}
.form-container .form-inner form{
    margin-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: var(--normal-font);
}
.form-container .form-inner form input[type="text"]{
    margin-bottom: 30px;
    padding: 15px 30px;
    border: none;
    outline: none;
}
.form-container .form-inner form .first-inputs{
    width: 49%;
}
.form-container .form-inner form .subject-input{
    width: 100%;
}
.form-container .form-inner form textarea{
    outline: none;
    width: 100%;
    border: none;
    padding: 15px 30px;
}
.form-container .form-inner form .button-holder{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.form-container .form-inner form .button-holder .sub-btn{
    padding: 15px 33px 15px 33px;
    background-color: var(--light-blue-color); 
    font-family: var(--normal-font);
    font-weight: bold;
    color: var(--dark-blue-color);
    text-decoration: none;
    transition: all 0.5s;
    align-self: center;
    border: 1px solid rgba(150, 150, 150, 0.3);
    cursor: pointer;
}
.form-container .form-inner form .button-holder .sub-btn.select{
    padding: 14px 10px 14px 10px;
    outline: none;
}
.form-container .form-inner form .button-holder .sub-btn:hover{
    color: var(--white-color);
}
@media screen and (max-width:500px) {
    .form-container .form-inner{
        width: 90%;
    }
    .form-container .form-inner form{
        flex-direction: column;
        align-items: center;
    }
    .form-container .form-inner form .first-inputs{
        width: 100%;
    }
    .form-container .form-inner form input[type="text"]{
        margin-bottom: 10px;
    }
}