.vision-container{
    margin-top: 50px;
    padding: 20px 0px 20px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-grey-color);
}
.vision-container.white-back{
    background-color: var(--white-color);
}
.vision-container .vision-inner{
    width: var(--inner-width);
    display: flex;
    justify-content: space-between;
}
.vision-container .vision-inner .side-coontent{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.vision-container .vision-inner .side-container{
    width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 2px solid var(--dark-grey-color);
    border-right: none;
}
.vision-container .vision-inner .side-container img{
    width: 100%;
}
.vision-container.white-back .vision-inner .side-container{
    margin-right: 10px;
    border-right: 2px solid var(--dark-grey-color);
    border-left: none;
}
.vision-container .vision-inner .side-coontent h1{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
}
.vision-container .vision-inner .side-coontent p{
    margin-top: 1px;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
    color: var(--dark-grey-color);
}
.vision-container .vision-inner .side-coontent .key-point-holder{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
} 
.vision-container .vision-inner .side-coontent .key-point-holder .point-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.vision-container .vision-inner .side-coontent .key-point-holder .point-row .checked{
    display: flex;
    align-items: flex-start;
    padding-right: 10px;
}
.vision-container .vision-inner .side-coontent .key-point-holder .point-row .checked img{
    margin-top: 2px;
    width: 20px;
}
.vision-container .vision-inner .side-coontent .key-point-holder .point-row .descrip{
    display: flex;
    flex-direction: column;
}
.vision-container .vision-inner .side-coontent .key-point-holder .point-row .descrip h4{
    font-family: var(--header-font);
    font-size: 16px;
    color: var(--dark-blue-color);
}
.vision-container .vision-inner .side-coontent .key-point-holder .point-row .descrip h4::after{
    content: ':';
    margin-left: 5px;
}
.vision-container .vision-inner .side-coontent .key-point-holder .point-row .descrip p{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    font-size: var(--font-s-normal);
}
@media screen and (max-width:500px) {
    .vision-container .vision-inner{
        width: 90%;
        flex-direction: column;
    }
    .vision-container .vision-inner .side-coontent{
        width: 100%;
    }
    .vision-container .vision-inner .side-container{
        display: none;
    }
}
