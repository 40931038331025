.tab-blog-container {
    padding: 10px 0px 10px 0px;
    margin-top: 20px;
    width: 80%;
    height: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-blog-container .input-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;
}

.tab-blog-container .input-container h3 {
    font-family: var(--header-font);
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tab-blog-container .input-container .det-row {
    padding: 5px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: var(--normal-font);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-width: 1px 0px;
}

.tab-blog-container .input-container .det-row label {
    width: 90px;
    text-align: right;
}

.tab-blog-container .input-container .det-row label::after {
    content: ':';
    margin-left: 3px;
}

.tab-blog-container .input-container .det-row input[type="file"] {
    background-color: #fff;
}

.tab-blog-container .input-container .det-row input[type="text"] {
    width: 70%;
    padding: 3px;
    outline: none;
}

.tab-blog-container .input-container .det-row textarea {
    width: 70%;
    padding: 3px;
    outline: none;
    resize: none;
    font-family: var(--normal-font);
}

.tab-blog-container .input-container .det-row a {
    text-decoration: none;
    margin: 0px 10px 0px 5px;
    padding: 3px 7px;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.tab-blog-container .input-container .det-row a:hover {
    background-color: var(--light-grey-color);
}

.tab-blog-container .input-container .det-row button {
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.tab-blog-container .input-container .det-row button:hover {
    background-color: var(--light-grey-color);
}

.tab-blog-container .input-container .guidline {
    color: red;
    font-size: 12px;
}

@media only screen and (max-width: 500px) {
    .tab-blog-container {
        width: 95%;
    }

    .tab-blog-container .input-container {
        width: 95%;
    }

    .tab-blog-container .input-container .det-row {
        width: 550px;
    }
}


.tab-blog-container .blog-table {
    margin: 10px 0px 20px 0px;
    width: 98%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.tab-blog-container .blog-table table {
    width: 100%;
}

.tab-blog-container .blog-table table thead {
    font-family: var(--header-font);
    font-size: 15px;
}

.tab-blog-container .blog-table table tbody {
    font-family: var(--normal-font);
    font-size: 14px;
}

.tab-blog-container .blog-table table,
th {
    border: 1px solid #bdbdbd;
    border-width: 0 1px 1px 1px;
    border-collapse: collapse;
    text-align: left;
}

.tab-blog-container .blog-table td {
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    text-align: left;
}

.tab-blog-container .blog-table th {
    position: sticky;
    top: 0;
    background-color: var(--dark-grey-color);
    color: #fff;
    text-align: center;
}

.tab-blog-container .blog-table tbody tr {
    background-color: #fff
}

.tab-blog-container .blog-table tbody tr:hover {
    background-color: var(--light-grey-color)
}

.tab-blog-container .blog-table thead tr {
    height: 50px;
}

.tab-blog-container .blog-table tbody tr {
    height: 40px;
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    transition: height 0.5s;
}

.tab-blog-container .blog-table tbody tr td {
    text-align: center;
    padding: 0px 5px 0px 5px;
}

.tab-blog-container .blog-table tbody tr td i {
    cursor: pointer;
    font-size: 16px;
    transition: all 0.15s;
}

.tab-blog-container .blog-table tbody tr td i:hover {
    color: red;
}

.tab-blog-container .blog-table tbody tr td input {
    padding: 5px;
    outline: none;
    text-align: center;
    font-size: 13px;
}

.tab-blog-container .blog-table tbody tr td .cell-div {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center
}
.tab-blog-container .blog-table tbody tr td button{
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin: 3px 0px 0px 5px;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 13px;
}
.tab-blog-container .blog-table tbody tr td button:hover {
    background-color: var(--light-grey-color);
}
.tab-blog-container .blog-table tbody tr td a{
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    color: #000;
}
.tab-blog-container .blog-table tbody tr td a:hover {
    background-color: var(--light-grey-color);
}


@media only screen and (max-width: 500px) {
    .tab-blog-container .blog-table {
        width: 95%;
        max-height: 320px;
    }

    .tab-blog-container .blog-table tbody tr td {
        padding-left: 10px;
        padding-right: 10px;
    }
}