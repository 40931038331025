.how-container{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.how-container .how-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.how-container .how-inner .sub-topic{
    padding: 8px 16px 8px 16px;
    background-color: var(--white-color);
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.how-container .how-inner .sub-topic img{
    width: 25px;
    height: 25px;
}
.how-container .how-inner .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
    font-size: var(--font-s-normal);
}
.how-container .how-inner .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
}
.how-container .how-inner .sub-description{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    margin-top: 5px;
    text-align: center;
    font-size: var(--font-s-normal);
}
.how-container .how-inner .cat-wise-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.how-container .how-inner .cat-wise-container .selector-container{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.how-container .how-inner .cat-wise-container .selector-container img{
    width: 100%;
}
.how-container .how-inner .cat-wise-container .selector-container .selector{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-grey-color);
    padding: 0px 30px 0px 30px;
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--dark-blue-color);
    font-weight: bold;
    border: none;
    font-size: 15px;
}
.how-container .how-inner .cat-wise-container .selector-container .selector h4{
    font-family: var(--header-font);
}
.how-container .how-inner .cat-wise-container .selector-container .selector.active{
    background-color: var(--light-blue-color);
}
.how-container .how-inner .cat-wise-container .selector-container .selector:hover{
    background-color: var(--light-blue-color);
}
.how-container .how-inner .cat-wise-container .content-container{
    width: 65%;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
}
.how-container .how-inner .cat-wise-container .content-container h2{
    font-family: var(--header-font);
    color: var(--dark-blue-color);
}
.how-container .how-inner .cat-wise-container .content-container .sub-descrip{
    margin-top: 10px;
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    font-size: var(--font-s-normal);
}
.how-container .how-inner .cat-wise-container .content-container .image{
    margin-top: 20px;
    width: 100%;
}
.how-container .how-inner .cat-wise-container .content-container .k-points{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.how-container .how-inner .cat-wise-container .content-container .k-points .k-point{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: flex-start;
}
.how-container .how-inner .cat-wise-container .content-container .k-points .k-point img{
    width: 20px;
    margin-top: 2px;
    margin-right: 5px;
}
.how-container .how-inner .cat-wise-container .content-container .k-points .k-point p{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    font-size: var(--font-s-normal);
}
@media screen and (max-width:500px) {
    .how-container .how-inner{
        width: 90%;
    }
    .how-container .how-inner .cat-wise-container{
        flex-direction: column;
    }
    .how-container .how-inner .cat-wise-container .selector-container{
        width: 100%;
    }
    .how-container .how-inner .cat-wise-container .selector-container img{
        display: none;
    }
    .how-container .how-inner .cat-wise-container .content-container{
        width: 100%;
        padding-left: 0;
    }
    .how-container .how-inner .cat-wise-container .content-container .k-points{
        flex-direction: column;
    }
    .how-container .how-inner .cat-wise-container .content-container .k-points .k-point{
        width: 100%;
    }
}