.settings-tab-container{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.settings-tab-container .settings-tab-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: column;
}
.settings-tab-container .settings-tab-inner .current-user{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.settings-tab-container .settings-tab-inner .current-user span{
    margin-bottom: 5px;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
}
.settings-tab-container .settings-tab-inner .current-user span:hover{
    background-color: var(--light-grey-color);
}
.settings-tab-container .settings-tab-inner .current-user span i{
    margin: 0px 2px 0px 2px;
}
.settings-tab-container .settings-tab-inner .tabpane-container{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.settings-tab-container .settings-tab-inner .tabpane-container .tab-header{
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.settings-tab-container .settings-tab-inner .tabpane-container .tab-header .tab-selector{
    height: 40px;
    margin: 2px 2px 2px 2px;
    padding: 5px 10px;
    background-color: var(--light-grey-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: var(--header-font);
    font-weight: bold;
    transition: all 0.5s;
}
.settings-tab-container .settings-tab-inner .tabpane-container .tab-header .tab-selector:hover{
    background-color: var(--dark-grey-color);
    color: white;
}
.settings-tab-container .settings-tab-inner .tabpane-container .tab-header .tab-selector.active{
    background-color: var(--dark-grey-color);
    color: white;
}
.settings-tab-container .settings-tab-inner .tabpane-container .content-holder{
    width: 100%;
    height: 450px;
}
.settings-tab-container .settings-tab-inner .tabpane-container .content-holder .tab-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: none;
    transform: translateX(-100%);
    transition: all 0.5s;
    background-color: var(--light-grey-color);
    overflow-y: auto;
    overflow-x: auto;
    padding-bottom: 30px;
}
.settings-tab-container .settings-tab-inner .tabpane-container .content-holder .tab-content.active{
    display: flex;
    transform: translateX(0%);
}


@media screen and (max-width:500px) {
    .settings-tab-container .settings-tab-inner{
        width: 90%;
        flex-direction: column;
    }
}