.nav-container{
    position: fixed;
    top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: top 0.25s ease 0s;
    z-index: 99999;
}
.nav-container.to-top{
    top:5px;
}
.nav-container .nav-bar{
    width: var(--inner-width);
    background-color: var(--light-blue-color);
    height: 70px;
    padding: 0px 60px 0px 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.nav-container .nav-bar .company-logo{
    display: none;
}
.nav-container .nav-bar ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    align-items: center;
}
.nav-container .nav-bar ul .contact{
    display: none;
}
.nav-container .nav-bar ul li{
    margin: 0px 40px 0px 0px;
}
.nav-container .nav-bar ul li a{
    text-decoration: none;
    color: var(--dark-blue-color);
    font-family: var(--normal-font);
    font-weight: bold;
    transition: all 0.25s;
}
.nav-container .nav-bar ul li a:hover{
    color: var(--white-color);
}
.nav-container .nav-bar ul li a.selected{
    color: var(--white-color);
}
.nav-container .nav-btn{
    padding: 15px;
    background-color: var(--dark-blue-color); 
    font-family: var(--normal-font);
    font-weight: bold;
    color: var(--white-color);
    text-decoration: none;
    transition: all 0.25s;
}
.nav-container .nav-btn:hover{
    color: var(--light-blue-color);
}
.nav-container .nav-btn.selected{
    color: var(--light-blue-color);
}
.nav-container .nav-close{
    display: none;
}
@media screen and (max-width:500px) {
    .nav-container{
        display: flex;
        top: 5px;
    }
    .nav-container .nav-bar{
        padding: 0;
        width: 90%;
        min-height: 70px;
        z-index: 9999;
    }
    .nav-container .nav-bar ul{
        position: absolute;
        flex-direction: column;
        justify-content: center;
        background-color: var(--light-blue-color);
        top: 0;
        width: 90%;
        left: -100%;
        right: 0%;
        min-height: 98vh;
        transition: left 0.5s ease;
        z-index: -1;
    }
    .nav-container .nav-bar.show ul{
        top: 0;
        left: 5%;
        right: 5%;
    }
    .nav-container .nav-bar ul .contact{
        display: block;
    }
    .nav-container .nav-bar ul .contact.selected{
        color: var(--white-color);
    }
    .nav-container .nav-bar ul li{
        margin: 0px 0px 40px 0px;
    }
    .nav-container .nav-bar ul li a.selected{
        color: var(--white-color);
    }
    .nav-btn{
        display: none;
    }
    .nav-container .nav-bar .company-logo{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .nav-container .nav-bar .company-logo span{
        display: none;
    }    
    .nav-container .nav-close{
        display: flex;
        padding: 15px;
    }
    .nav-container .nav-close i{
        font-size: var(--font-s-big);
        color: var(--dark-blue-color);
        transition: all 0.5s;
        cursor: pointer;
    }
    .nav-container .nav-close i:hover{
        color: var(--white-color);
    }
}