.user-table{
    margin-top: 20px;
    width: 80%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.user-table table {
    width: 100%;
}
.user-table table thead{
    font-family: var(--header-font);
    font-size: 15px;
}
.user-table table tbody tr td:nth-child(3){
    min-width: 100px;
}
.user-table table tbody tr td:nth-child(4){
    min-width: 100px;
}
.user-table table tbody tr td:nth-child(5){
    min-width: 250px;
}
.user-table table tbody{
    font-family: var(--normal-font);
    font-size: 14px;
}
.user-table table, th{
    border: 1px solid #bdbdbd;
    border-width: 0 1px 1px 1px;
    border-collapse: collapse;
    text-align: left;
}
.user-table td{
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    text-align: left;
}
.user-table th{
    position: sticky;
    top: 0;
    background-color: var(--dark-grey-color);
    color: #fff;
    text-align: center;
}
.user-table tbody tr{
    background-color:#fff;
}
.user-table tbody tr:hover{
    background-color:var(--light-grey-color);
}
.user-table thead tr{
    height: 50px;
}
.user-table tbody tr{
    height: 40px;
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    transition: height 0.5s;
}
.user-table tbody tr td{
    text-align: center;
    padding: 0px 10px 0px 10px;
}
.user-table tbody tr td i{
    margin-left: 10px;
    margin-right: -10px;
    cursor: pointer;
}
.user-table tbody tr td i:hover{
    color: red;
}
@media only screen and (max-width: 500px) {
    .user-table{
        width: 95%;
        max-height: 320px;
    }
    .user-table tbody tr td{
        padding-left: 10px;
        padding-right: 10px;
    }
}