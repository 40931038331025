.hero-section{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section .bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.hero-section .bottom .hero-home-image{
    display: flex;
    width: 100%;
}
.hero-section .bottom .hero-home-image img{
    width: 100%;
}
.hero-section .bottom .content-conatiner{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 200px;
    width: 100%;
}
.hero-section .bottom .content-conatiner .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: var(--inner-width);
    height: auto;
    color: var(--white-color);
}
.hero-section .bottom .content-conatiner .content .sub-line{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.2);
}
.hero-section .bottom .content-conatiner .content .sub-line p{
    margin-left: 5px;
    font-family: var(--normal-font);
    font-size: 15px;
}
.hero-section .bottom .content-conatiner .content .hero-title{
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hero-section .bottom .content-conatiner .content .hero-title h2{
    margin: 0;
    padding: 0;
    font-family: var(--header-font);
    font-size: 50px;
}
.hero-section .bottom .content-conatiner .content .shop-now-button{
    margin-top: 20px;
    padding: 20px 33px 20px 33px;
    background-color: var(--light-blue-color); 
    font-family: var(--normal-font);
    font-weight: bold;
    color: var(--dark-blue-color);
    text-decoration: none;
    transition: all 0.5s;
}
.hero-section .bottom .content-conatiner .content .shop-now-button:hover{
    color: var(--white-color);
}
@media screen and (max-width:500px) {
    .hero-section{display: flex;}
    .hero-section .bottom .content-conatiner{
        top: 150px;
    }
    .hero-section .bottom .content-conatiner .content{
        width: 90%;
    }
    .hero-section .bottom .hero-home-image{
        width: 100%;
        height: 100vh;
    }
    .hero-section .bottom .hero-home-image img{ 
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .hero-section .bottom .content-conatiner .content .hero-title{
        width: 90%;
    }
    .hero-section .bottom .content-conatiner .content .hero-title h2{
        font-size:  33px;
    }
    .hero-section .bottom .content-conatiner .content .sub-line{
        background-color: rgba(180, 180, 180, 0.4);
    }
    .hero-section .bottom .content-conatiner .content .sub-line p{
        font-size: 13px;
    }
    .hero-section .bottom .content-conatiner .content .shop-now-button{
        margin-top: 10px;
        padding: 15px 20px 15px 20px;
        font-size: 16px;
    }
}