.map-container{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.map-container .map-inner{
    width: var(--inner-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}
@media screen and (max-width:500px) {
    .map-container .map-inner{
        width: 90%;
    }
    .map-container .map-inner iframe{
        height: 400px;
    }
}
