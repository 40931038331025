.service-container{
    margin-top: 50px;
    padding-bottom: 40px;
    width: 100%;
    height: auto;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.service-container .service-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.service-container .service-inner .sub-topic{
    padding: 8px 16px 8px 16px;
    background-color: var(--white-color);
    width: 160px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.service-container .service-inner .sub-topic img{
    width: 25px;
    height: 25px;
}
.service-container .service-inner .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
}
.service-container .service-inner .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
}
.service-container .service-inner .sub-description{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    margin-top: 5px;
}
.service-container .service-inner .card-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.service-container .service-inner .card-container .card{
    min-width: 230px;
    max-width: 230px;
    min-height: 220px;
    background-color: var(--dark-blue-color);
    padding: 30px 10px 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.service-container .service-inner .card-container .card img{
    width: 50px;
    height: 50px;
}
.service-container .service-inner .card-container .card h3{
    margin-top: 10px;
    color: var(--light-blue-color);
    font-family: var(--header-font);
    font-size: 16px;
}
.service-container .service-inner .card-container .card p{
    margin-top: 10px;
    color: var(--light-grey-color);
    font-family: var(--normal-font);
    font-size: 13px;
}
@media screen and (max-width:500px) {
    .service-container .service-inner{
        width: 90%;
    }
    .service-container .service-inner .card-container{
        justify-content: center;
    }
    .service-container .service-inner .card-container .card{
        margin: 10px;
        min-width: 100%;
        min-height: 270px;
    }
}
