.articles-container{
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.articles-container .articles-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.articles-container .articles-inner .sub-topic{
    padding: 8px 16px 8px 16px;
    background-color: var(--white-color);
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.articles-container .articles-inner .sub-topic img{
    width: 25px;
    height: 25px;
}
.articles-container .articles-inner .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
    font-size: var(--font-s-normal);
}
.articles-container .articles-inner .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
    text-align: center;
}
.articles-container .articles-inner .articles{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.articles-container .articles-inner .articles .article{
    width: 49%;
    height: 500px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.articles-container .articles-inner .articles .article .article-image{
    width: 100%;
    height: 55%;
    overflow: hidden;
}
.articles-container .articles-inner .articles .article .article-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
}
.articles-container .articles-inner .articles .article .article-image img:hover{
    transform: scale(1.2);
}
.articles-container .articles-inner .articles .article .article-subdet{
    margin-top: -25px;
    width: 70%;
    height: 50px;
    align-self: center;
    position: absolute;
    top: 55%;
    display: flex;
}
.articles-container .articles-inner .articles .article .article-subdet .side{
    width: 50%;
    height: 100%;
    font-family: var(--header-font);
    font-size: 15px;
    color: var(--dark-blue-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.articles-container .articles-inner .articles .article .article-subdet .side.author{
    background-color: var(--dark-grey-color);
}
.articles-container .articles-inner .articles .article .article-subdet .side.date{
    background-color: var(--light-blue-color);
}
.articles-container .articles-inner .articles .article .article-topic{
    margin-top: 6%;
    font-family: var(--header-font);
    color: var(--dark-blue-color);
}
.articles-container .articles-inner .articles .article .article-descrip{
    margin-top: 10px;
    width: 100%;
    max-height: 14%;
    overflow: hidden;
}
.articles-container .articles-inner .articles .article .article-descrip p{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    font-size: var(--font-s-normal);
}
.articles-container .articles-inner .articles .article .read-more{
    margin-top: 10px;
    font-family: var(--header-font);
    color: var(--dark-blue-color);
    font-size: 15px;
    font-weight: bold;
    cursor: pointer !important;
    text-decoration: none;
    z-index: 1000;
}
.articles-container .articles-inner .nxt-btn{
    padding: 20px 33px 20px 33px;
    background-color: var(--light-blue-color); 
    font-family: var(--normal-font);
    font-weight: bold;
    color: var(--dark-blue-color);
    text-decoration: none;
    transition: all 0.5s;
}
.articles-container .articles-inner .nxt-btn:hover{
    color: var(--white-color);
}
@media screen and (max-width:500px) {
    .articles-container .articles-inner{
        width: 90%;
    }
    .articles-container .articles-inner .articles{
        flex-direction: column;
    }
    .articles-container .articles-inner .articles .article{
        width: 100%;
        height: 400px;
    }
    .articles-container .articles-inner .articles .article .article-image{
        height: 45%;
    }
    .articles-container .articles-inner .articles .article .article-subdet{
        margin-top: -20px;
        width: 90%;
        top: 45%;
        height: 40px;
    }
    .articles-container .articles-inner .articles .article .article-subdet .side{
        font-size: 12px;
    }
    .articles-container .articles-inner .articles .article .article-topic{
        margin-top: 8%;
    }
}