.conatct-det-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.conatct-det-container .conatct-det-inner{
    width: var(--inner-width);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.conatct-det-container .conatct-det-inner .sub-topic{
    padding: 8px 16px 8px 16px;
    background-color: var(--white-color);
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.conatct-det-container .conatct-det-inner .sub-topic img{
    width: 25px;
    height: 25px;
}
.conatct-det-container .conatct-det-inner .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
    font-size: var(--font-s-normal);
}
.conatct-det-container .conatct-det-inner .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
    text-align: center;
}
.conatct-det-container .conatct-det-inner .branch-container{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.conatct-det-container .conatct-det-inner .branch-container .branch{
    width: 33%;
    height: 300px;
    background-color: var(--light-blue-color);
    padding: 50px;
    transition: all 0.20s;
    border-right: 1px solid var(--white-color);
}
.conatct-det-container .conatct-det-inner .branch-container .branch h4{
    width: 100%;
    text-align: center;
    font-family: var(--header-font);
    color: var(--dark-blue-color);
    font-size: 16px;
    margin-bottom: 10px;
}
.conatct-det-container .conatct-det-inner .branch-container .branch h5{
    width: 100%;
    text-align: left;
    font-family: var(--header-font);
    color: var(--dark-blue-color);
    font-size: 15px;
}
.conatct-det-container .conatct-det-inner .branch-container .branch a{
    display: inline-block;
    width: 100%;
    text-align: left;
    font-family: var(--normal-font);
    color: var(--white-color);
    font-size: 13px;
    border-bottom: 1px solid var(--white-color);
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}
.conatct-det-container .conatct-det-inner .branch-container .branch:hover{
    background-color: var(--dark-blue-color);
    transform: scale(1.1);
}
.conatct-det-container .conatct-det-inner .branch-container .branch:hover h4{
    color: var(--light-blue-color);
}
.conatct-det-container .conatct-det-inner .branch-container .branch:hover h5{
    color: var(--light-blue-color);
}
/* .conatct-det-container .conatct-det-inner .branch-container .branch.main{
    background-color: var(--dark-blue-color);
    transform: scale(1.1);
}
.conatct-det-container .conatct-det-inner .branch-container .branch.main h4{
    color: var(--light-blue-color);
}
.conatct-det-container .conatct-det-inner .branch-container .branch.main h5{
    color: var(--light-blue-color);
} */
@media screen and (max-width:500px) {
    .conatct-det-container .conatct-det-inner{
        width: 90%;
    }
    .conatct-det-container .conatct-det-inner .branch-container{
        flex-direction: column;
        align-items: center;
    }
    .conatct-det-container .conatct-det-inner .branch-container .branch{
        width: 90%;
    }
}