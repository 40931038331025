.about-container{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-container .about-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}
.about-container .about-inner.reverse{
    flex-direction: row-reverse;
}
.about-container .about-inner .image-container{
    width: 540px;
    height: auto;
}
.about-container .about-inner .image-container img{
    width: 100%;
}
.about-container .about-inner .text-container{
    width: 600px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.about-container .about-inner .text-container .sub-header{
    padding: 5px 20px 5px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--light-grey-color);
}
.about-container .about-inner .text-container .sub-header p{
    padding-left: 10px;
    font-family: var(--normal-font);
}
.about-container .about-inner .text-container .main-header{
    margin-top: 10px;
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
    font-weight: bold; 
}
.about-container .about-inner .text-container .about-description{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
    text-align: left;
    color: var(--dark-grey-color);
}
.about-container .about-inner .text-container .key-points{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.about-container .about-inner .text-container .key-points .point{
    margin-bottom: 5px;
    width: 49%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.about-container .about-inner .text-container .key-points .point img{
    width: 20px;
    height: 20px;
}
.about-container .about-inner .text-container .key-points .point p{
    margin-left: 5px;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
    color: var(--dark-grey-color);
}
@media screen and (max-width:500px) {
    .about-container{
        display: flex;
    }
    .about-container .about-inner{
        width: 90%;
        flex-direction: column;
        gap: 10px;
    }
    .about-container .about-inner.reverse{
        flex-direction: column-reverse;
    }
    .about-container .about-inner .image-container{
        width: 100%;
    }
    .about-container .about-inner .text-container{
        width: 100%;
        padding-left: 0px;
    }
    .about-container .about-inner .text-container .about-description{
        margin-bottom: 10px;
    }
    .about-container .about-inner .text-container .key-points{
        margin-top: 0px;
        flex-direction: column;
    }
    .about-container .about-inner .text-container .key-points .point{
        margin-top: 5px;
        width: 90%;
    }
}
