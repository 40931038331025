.contact-table{
    margin-top: 20px;
    width: 98%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.contact-table table {
    width: 100%;
}
.contact-table table thead{
    font-family: var(--header-font);
    font-size: 15px;
}
.contact-table table tbody{
    font-family: var(--normal-font);
    font-size: 14px;
}
.contact-table table, th{
    border: 1px solid #bdbdbd;
    border-width: 0 1px 1px 1px;
    border-collapse: collapse;
    text-align: left;
}
.contact-table td{
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    text-align: left;
}
.contact-table th{
    position: sticky;
    top: 0;
    background-color: var(--dark-grey-color);
    color: #fff;
    text-align: center;
}
.contact-table tbody tr{
    background-color:#fff
}
.contact-table tbody tr:hover{
    background-color:var(--light-grey-color)
}
.contact-table thead tr{
    height: 50px;
}
.contact-table tbody tr{
    height: 50px;
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    transition: height 0.5s;
}
.contact-table tbody tr td{
    text-align: center;
    padding: 0px 10px 0px 10px;
}
.contact-table tbody tr td i{
    cursor: pointer;
    font-size: 16px;
    transition: all 0.15s;
}
.contact-table tbody tr td i:hover{
    color: red;
}
.contact-table tbody tr td input{
    padding: 5px;
    outline: none;
}
.contact-table tbody tr td:nth-child(2) input{
    width: 100px;
}
.contact-table tbody tr td:nth-child(3) input{
    width: 230px;
}
.contact-table tbody tr td:nth-child(4) input{
    width: 80px;
}
.contact-table tbody tr td:nth-child(5) input{
    width: 80px;
}
@media only screen and (max-width: 500px) {
    .contact-table{
        width: 95%;
        max-height: 320px;
    }
    .contact-table tbody tr td{
        padding-left: 10px;
        padding-right: 10px;
    }
}