.hero-section .top{
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hero-section .top .content{
    width: var(--inner-width);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.hero-section .top .content .company-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hero-section .top .content .company-logo span{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    font-weight: bold;
    color: var(--dark-blue-color);
}
.hero-section .top .content .company-contacts{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hero-section .top .content .company-contacts .contact-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
}
.hero-section .top .content .company-contacts .contact-type img{
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.hero-section .top .content .company-contacts .contact-type img:hover{
    transform: scale(1.1);
}
.hero-section .top .content .company-contacts .contact-type .contact-details{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    display: none;
}
.hero-section .top .content .company-contacts .contact-type.mail .show-mail{
    display: flex;
}
.hero-section .top .content .company-contacts .contact-type.call .show-call{
    display: flex;
}
.hero-section .top .content .company-contacts .contact-type .contact-details span{
    font-size: var(--font-s-normal);
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
}
@media screen and (max-width:500px) {
    .hero-section .top{
        display: none;
    }
}