@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap');

:root {
    --inner-width: 964px;
    --light-blue-color: #0DBBFC;
    --dark-blue-color: #1C2752;
    --light-grey-color: #F4F4F4;
    --dark-grey-color: #4F4F4F;
    --black-color: #000;
    --white-color: #fff;

    --header-font: 'Mulish', sans-serif;
    --normal-font: 'Inter', sans-serif;

    --font-s-big: 40px;
    --font-s-normal: 14px;
}
@media screen and (max-width:500px) {
    :root {
        --font-s-big: 30px;
        --font-s-normal: 13px;
    }
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.container {
    width: 100%;
    height: 100%;
}

.loading-gif-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
}

.loading-gif-container img {
    width: 70px;
    height: 70px;
}