.footer-container{
    margin-top: 50px;
    width: 100%;
    height: auto;
    background-color: var(--dark-blue-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--white-color);
}
.footer-container .top{
    width: var(--inner-width);
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 20px 0px;
    border-bottom: 0.5px solid var(--dark-grey-color);
}
.footer-container .top .content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.footer-container .top .content .company-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.footer-container .top .content .company-logo span{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    font-weight: bold;
}
.footer-container .top .content .company-contacts{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.footer-container .top .content .company-contacts .contact-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
}
.footer-container .top .content .company-contacts .contact-type img{
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.footer-container .top .content .company-contacts .contact-type img:hover{
    transform: scale(1.1);
}
.footer-container .top .content .company-contacts .contact-type .contact-details{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    display: none;
}
.footer-container .top .content .company-contacts .contact-type.mail .show-mail{
    display: flex;
}
.footer-container .top .content .company-contacts .contact-type.call .show-call{
    display: flex;
}
.footer-container .top .content .company-contacts .contact-type.address .show-address{
    display: flex;
}
.footer-container .top .content .company-contacts .contact-type .contact-details span{
    font-size: var(--font-s-normal);
    font-family: var(--normal-font);
}
.footer-container .middle{
    margin: 40px 0px 40px 0px;
    width: var(--inner-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer-container .middle .mini-descrip{
    display: flex;
    flex-direction: column;
    width: 60%;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
    border-right: 0.5px solid var(--dark-grey-color);
}
.footer-container .middle .mini-descrip .social-logo{
    margin-top: 10px;
}
.footer-container .middle .mini-descrip .social-logo img{
    margin-right: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.footer-container .middle .mini-descrip .social-logo img:hover{
    transform: scale(1.2);
}
.footer-container .middle .footer-links{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 0.5px solid var(--dark-grey-color);
}
.footer-container .middle .footer-links h4{
    font-family: var(--header-font);
    font-size: 16px;
}
.footer-container .middle .footer-links a{
    color: var(--white-color);
    text-decoration: none;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
}
.footer-container .middle .footer-links .links-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.footer-container .bottom{
    padding: 20px 0px 20px 0px;
    width: var(--inner-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 0.5px solid var(--dark-grey-color);
}
.footer-container .bottom p{
    text-align: center;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
}
.footer-container .bottom p a{
    text-decoration: none;
    color: var(--white-color);
}
@media screen and (max-width:500px) {
    .footer-container .top{
        width: 90%;
    }
    .footer-container .top .content{
        justify-content: center;
    }
    .footer-container .top .content .company-contacts{
        display: none;
    }
    .footer-container .middle{
        width: 90%;
        flex-direction: column;
    }
    .footer-container .middle .mini-descrip{
        width: 100%;
        border-right: none;
        align-items: center;
    }
    .footer-container .middle .mini-descrip p{
        text-align: center;
    }
    .footer-container .middle .footer-links{
        display: none;
    }
    .footer-container .bottom{
        width: 90%;
    }
}