.article-container{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.article-container .article-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.article-container .article-inner h1{
    width: var(--inner-width);
    font-size: var(--font-s-big);
    font-family: var(--header-font);
    color: var(--dark-blue-color);
    text-align: left;
}
.article-container .article-inner .article-image{
    margin-top: 20px;
    width: 50%;
    height: auto;
    overflow: hidden;
}
.article-container .article-inner .article-image img{
    width: 100%;
}
.article-container .article-inner .article-subdet{
    margin-top: -25px;
    margin-bottom: 20px;
    width: 40%;
    height: 50px;
    align-self: center;
    top: 55%;
    display: flex;
}
.article-container .article-inner .article-subdet .side{
    width: 50%;
    height: 100%;
    font-family: var(--header-font);
    font-size: 15px;
    color: var(--dark-blue-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.article-container .article-inner .article-subdet .side.author{
    background-color: var(--dark-grey-color);
}
.article-container .article-inner .article-subdet .side.date{
    background-color: var(--light-blue-color);
}
.article-container .article-inner p{
    width: 80%;
    font-family: var(--normal-font);
    font-size: var(--font-s-normal);
    color: var(--dark-grey-color);
    margin-bottom: 20px;
    text-align: justify;
}
@media screen and (max-width:500px) {
    .article-container .article-inner{
        width: 90%;
    }
    .article-container .article-inner h1{
        width: 100%;
    }
    .article-container .article-inner .article-image{
        width: 100%;
    }
    .article-container .article-inner .article-subdet{
        width: 90%;
        height: 40px;
    }
    .article-container .article-inner .article-subdet .side{
        font-size: 12px;
    }
    .article-container .article-inner p{
        width: 100%;
    }
}