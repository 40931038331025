.hero-section{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hero-section .bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.hero-section .bottom .hero-image{
    display: flex;
    width: 100%;
}
.hero-section .bottom .hero-image img{
    width: 100%;
}
.hero-section .bottom h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--header-font);
    color: var(--white-color);
    font-size: var(--font-s-big);
    text-align: center;
}
@media screen and (max-width:500px) {
    .hero-section{display: flex;}
    .hero-section .bottom .hero-image{
        width: 100%;
        height: 60vh;
    }
    .hero-section .bottom .hero-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .hero-section .bottom h1{
        font-size: var(--font-s-big);
    }
}
