.tab-about--container {
    padding: 10px 0px 10px 0px;
    margin-top: 20px;
    width: 80%;
    height: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-about--container .input-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;
}

.tab-about--container .input-container h3 {
    font-family: var(--header-font);
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tab-about--container .input-container .det-row {
    padding: 5px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: var(--normal-font);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-width: 1px 0px;
}

.tab-about--container .input-container .det-row label {
    width: 90px;
    text-align: right;
}

.tab-about--container .input-container .det-row label::after {
    content: ':';
    margin-left: 3px;
}

.tab-about--container .input-container .det-row input[type="file"] {
    background-color: #fff;
}

.tab-about--container .input-container .det-row input[type="text"] {
    width: 70%;
    padding: 3px;
    outline: none;
}

.tab-about--container .input-container .det-row textarea {
    width: 70%;
    padding: 3px;
    outline: none;
    resize: none;
    font-family: var(--normal-font);
}

.tab-about--container .input-container .det-row a {
    text-decoration: none;
    margin: 0px 10px 0px 5px;
    padding: 3px 7px;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.tab-about--container .input-container .det-row a:hover {
    background-color: var(--light-grey-color);
}

.tab-about--container .input-container .det-row button {
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.tab-about--container .input-container .det-row button:hover {
    background-color: var(--light-grey-color);
}

.tab-about--container .input-container .guidline {
    color: red;
    font-size: 12px;
}

@media only screen and (max-width: 500px) {
    .tab-about--container {
        width: 95%;
    }

    .tab-about--container .input-container {
        width: 95%;
    }

    .tab-about--container .input-container .det-row {
        width: 550px;
    }
}

.tab-about--container .team-table {
    margin: 10px 0px 20px 0px;
    width: 98%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.tab-about--container .team-table table {
    width: 100%;
}

.tab-about--container .team-table table thead {
    font-family: var(--header-font);
    font-size: 15px;
}

.tab-about--container .team-table table tbody {
    font-family: var(--normal-font);
    font-size: 14px;
}

.tab-about--container .team-table table,
th {
    border: 1px solid #bdbdbd;
    border-width: 0 1px 1px 1px;
    border-collapse: collapse;
    text-align: left;
}

.tab-about--container .team-table td {
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    text-align: left;
}

.tab-about--container .team-table th {
    position: sticky;
    top: 0;
    background-color: var(--dark-grey-color);
    color: #fff;
    text-align: center;
}

.tab-about--container .team-table tbody tr {
    background-color: #fff
}

.tab-about--container .team-table tbody tr:hover {
    background-color: var(--light-grey-color)
}

.tab-about--container .team-table thead tr {
    height: 50px;
}

.tab-about--container .team-table tbody tr {
    height: 50px;
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    transition: height 0.5s;
}

.tab-about--container .team-table tbody tr td {
    text-align: center;
    padding: 0px 10px 0px 10px;
}

.tab-about--container .team-table tbody tr td i {
    cursor: pointer;
    font-size: 16px;
    transition: all 0.15s;
}

.tab-about--container .team-table tbody tr td i:hover {
    color: red;
}

.tab-about--container .team-table tbody tr td input {
    padding: 5px;
    outline: none;
    text-align: center;
    font-size: 13px;
}

.tab-about--container .team-table tbody tr td .cell-div {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center
}
.tab-about--container .team-table tbody tr td .cell-div button{
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin: 3px 0px 0px 0px;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 13px;
}
.tab-about--container .team-table tbody tr td .cell-div button:hover {
    background-color: var(--light-grey-color);
}
.tab-about--container .team-table tbody tr td .cell-div a{
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    color: #000;
}
.tab-about--container .team-table tbody tr td .cell-div a:hover {
    background-color: var(--light-grey-color);
}

.tab-about--container .team-table tbody tr td:nth-child(2) {
    max-width: 150px;
}
.tab-about--container .team-table tbody tr td:nth-child(3) {
    max-width: 200px;
}
.tab-about--container .team-table tbody tr td:nth-child(3) input{
    min-width: 240px;
}
.tab-about--container .team-table tbody tr td:nth-child(4) {
    max-width: 200px;
}
.tab-about--container .team-table tbody tr td:nth-child(4) input{
    min-width: 240px;
}

/* .tab-about--container .team-table tbody tr td:nth-child(3) input{
    width: 230px;
}
.tab-about--container .team-table tbody tr td:nth-child(4) input{
    width: 80px;
}
.tab-about--container .team-table tbody tr td:nth-child(5) input{
    width: 80px;
} */
@media only screen and (max-width: 500px) {
    .tab-about--container .team-table {
        width: 95%;
        max-height: 320px;
    }

    .tab-about--container .team-table tbody tr td {
        padding-left: 10px;
        padding-right: 10px;
    }
}