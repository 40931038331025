.log-form-container{
    margin-top: 50px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.log-form-container .log-form-inner{
    width: var(--inner-width);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.log-form-container .log-form-inner form{
    width: 300px;
    font-family: var(--normal-font);
    height: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.5);
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.log-form-container .log-form-inner form input{
    outline: none;
    padding: 5px 10px;
}
.log-form-container .log-form-inner form button{
    padding: 10px;
    background-color: var(--dark-blue-color);
    font-family: var(--header-font);
    font-weight: bold;
    color: var(--white-color);
    transition: all 0.25s;
    font-size: 20px;
    cursor: pointer;
}
.log-form-container .log-form-inner form .field{
    display: flex;
    flex-direction: column;
}
@media screen and (max-width:500px) {
    .log-form-container .log-form-inner{
        width: 90%;
        flex-direction: column;
    }
}