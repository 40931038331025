.tab-home--container{
    padding: 10px 0px 10px 0px;
    margin-top: 20px;
    width: 80%;
    height: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tab-home--container .inp-container{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;
}
.tab-home--container .inp-container h3{
    font-family: var(--header-font);
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.tab-home--container .inp-container .det-row{
    padding: 5px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: var(--normal-font);
    border: 1px solid rgba(0,0,0,0.2);
    border-width: 1px 0px;
}
.tab-home--container .inp-container .det-row label{
    width: 90px;
    text-align: right;
}
.tab-home--container .inp-container .det-row label::after{
    content: ':';
    margin-left: 3px;
}
.tab-home--container .inp-container .det-row input[type="file"]{
    background-color: #fff;
}
.tab-home--container .inp-container .det-row input[type="text"]{
    width: 70%;
    padding: 3px;
    outline: none;
}
.tab-home--container .inp-container .det-row textarea{
    width: 70%;
    padding: 3px;
    outline: none;
    resize: none;
    font-family: var(--normal-font);
}
.tab-home--container .inp-container .det-row a{
    text-decoration: none;
    margin: 0px 10px 0px 5px;
    padding: 3px 7px;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    border: 1px solid rgba(0,0,0,0.3);
}
.tab-home--container .inp-container .det-row a:hover{
    background-color: var(--light-grey-color);
}
.tab-home--container .inp-container .det-row button{
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0,0,0,0.3);
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    cursor: pointer;
}
.tab-home--container .inp-container .det-row button:hover{
    background-color: var(--light-grey-color);
}
.tab-home--container .inp-container .guidline{
    color: red;
    font-size: 12px;
}

@media only screen and (max-width: 500px) {
    .tab-home--container{
        width: 95%;
    }
    .tab-home--container .inp-container{
        width: 95%;
    }
    .tab-home--container .inp-container .det-row{
        width: 550px;
    }
}