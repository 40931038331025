.team-container{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-grey-color);
}
.team-container .team-inner{
    width: var(--inner-width);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.team-container .team-inner .sub-topic{
    padding: 8px 16px 8px 16px;
    background-color: var(--light-grey-color);
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.team-container .team-inner .sub-topic img{
    width: 25px;
    height: 25px;
}
.team-container .team-inner .sub-topic p{
    font-family: var(--normal-font);
    color: var(--dark-blue-color);
    font-weight: bold;
}
.team-container .team-inner .main-topic{
    font-family: var(--header-font);
    font-size: var(--font-s-big);
    color: var(--dark-blue-color);
}
.team-container .team-inner .sub-description{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    margin-top: 5px;
}
.team-container .team-inner .members-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    gap: 15px;
}
.team-container .team-inner .members-container .member{
    min-width: 32%;
    height: 380px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.team-container .team-inner .members-container .member img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.team-container .team-inner .members-container .member .details{
    height: 20%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
.team-container .team-inner .members-container .member .details h4{ 
    font-family: var(--header-font);
    color: var(--dark-blue-color);
    font-size: 15px;
    text-align: center;
}
.team-container .team-inner .members-container .member .details p{
    font-family: var(--normal-font);
    color: var(--dark-grey-color);
    font-size: var(--font-s-normal);
}
@media screen and (max-width:500px) {
    .team-container .team-inner{
        width: 90%;
    }
    .team-container .team-inner .members-container{
        flex-direction: row;
        align-items: center;
    }
    .team-container .team-inner .members-container .member{
        margin-bottom: 15px;
        min-width: 80%;
        height: 300px;
    }
}